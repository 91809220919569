import React, {useState, useEffect} from 'react'
import ReactDOM from 'react-dom'
import axios from "axios";
import Question from "./components/question";
import UserData from "./components/user_data";
import Result from "./components/result";

function App({app_id}) {
    const [data, setData] = useState([]);
    const [answer, setAnswer] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [endText, setEndText] = useState("")
    const [terms, setTerms] = useState("")
    const [result, setResult] = useState(false);
    const [form, setForm] = useState([]);
    const [css, setCss] = useState("#0B2F79");
    const [ip, setIp] = useState("");
    const [color, setColor] = useState('#FFFFFF');
    const [catId, setCatId] = useState('#FFFFFF');
    const [section, setSection] = useState(0);
    const [current, setCurrent] = useState(1);
    const [voter, setVoter] = useState([]);
    const dataUrl = window.location.origin + "/services/quiz/get/";
    const ipUrl = window.location.hostname !== 'local-sn.delo.si' ? "https://uporabnik.delo.si/services/promo/collect/" :
        "https://local-dur.delo.si/services/promo/collect/";
    const pollUrl = window.location.hostname !== 'local-sn.delo.si' ? "https://uporabnik.delo.si/services/promo/poll/" :
        "https://local-dur.delo.si/services/promo/poll/";

    const fetchData = new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve({
                data: {
                    "id": 22,
                    "title": "Anketa",
                    "type": "poll",
                    "live": true,
                    "first_published_at": null,
                    "name": "",
                    "description": "&lt;p&gt;adfasdf&lt;/p&gt;\r\n",
                    "questions": [
                        {
                            "id": "0",
                            "question": "vprašanje",
                            "image": "/media/original_images/se/kviz/22/",
                            "image_credits": "",
                            "state": 1,
                            "answers": [
                                {
                                    "id": "0",
                                    "answer": "1",
                                    "points_up": "0",
                                    "points_down": "0",
                                    "correct": 0,
                                    "state": 1
                                },
                                {
                                    "id": "0",
                                    "answer": "2",
                                    "points_up": "0",
                                    "points_down": "0",
                                    "correct": 0,
                                    "state": 1
                                }
                            ]
                        },
                        {
                            "id": "0",
                            "question": "Odgovor dva",
                            "image": "/media/original_images/se/kviz/22/",
                            "image_credits": "",
                            "state": 1,
                            "answers": [
                                {
                                    "id": "0",
                                    "answer": "1",
                                    "points_up": "0",
                                    "points_down": "0",
                                    "correct": 0,
                                    "state": 1
                                },
                                {
                                    "id": "0",
                                    "answer": "2",
                                    "points_up": "0",
                                    "points_down": "0",
                                    "correct": 0,
                                    "state": 1
                                },
                                {
                                    "id": "0",
                                    "answer": "3",
                                    "points_up": "0",
                                    "points_down": "0",
                                    "correct": 0,
                                    "state": 1
                                },
                                {
                                    "id": "0",
                                    "answer": "4",
                                    "points_up": "0",
                                    "points_down": "0",
                                    "correct": 0,
                                    "state": 1
                                }
                            ]
                        },
                        {
                            "id": "0",
                            "question": "Tretje",
                            "image": "/media/original_images/se/kviz/22/",
                            "image_credits": "",
                            "state": 1,
                            "answers": [
                                {
                                    "id": "0",
                                    "answer": "1",
                                    "points_up": "0",
                                    "points_down": "0",
                                    "correct": 0,
                                    "state": 1
                                },
                                {
                                    "id": "0",
                                    "answer": "2",
                                    "points_up": "0",
                                    "points_down": "0",
                                    "correct": 0,
                                    "state": 1
                                },
                                {
                                    "id": "0",
                                    "answer": "3",
                                    "points_up": "0",
                                    "points_down": "0",
                                    "correct": 0,
                                    "state": 1
                                }
                            ]
                        }
                    ],
                    "result_msgs": "{'0': '', '1_10': '', '11_20': '', '21_30': '', '31_40': '', '41_50': '', '51_60': '', '61_70': '', '71_80': '', '81_90': '', '91_99': '', '100': ''}",
                    "image": "/media/original_images/se/kviz/22/klobuk.JPG",
                    "cat_id": 4,
                    "end_text": "",
                    "terms_of_use_url": "",
                    "form": []
                }

            });
        }, 300);
    });

    const fetchQuestions = async () => {
        const response = await axios.get(dataUrl + app_id);
        // const response = await fetchData;

        setData(response.data);
        setCatId(response.data.cat_id);
        setColor(response.data.name);
        setQuestions(response.data.questions);
        setEndText(response.data.end_text);
        setTerms(response.data.terms_of_use_url);
        let f = response.data.form;
        for (let i = 0; i < f.length; i++) {
            if (f[i].type == "checkbox") {
                f[i].checked = ""; //f[i].required == 1 ? true : false;
            }
        }
        setForm(f);
    }

    const classByDomain = () => {
        let domain = window.location.origin;
        let portals = [
            {'css': 'delo', 'domain': 'www.delo.si', 'color': '#0B2F79'},
            {'css': 'delo', 'domain': 'poc-delo.stage.delo.si', 'color': '#0B2F79'},
            {'css': 'delo', 'domain': 'local-delo.delo.si', 'color': '#0B2F79'},
            {'css': 'sn', 'domain': 'slovenskenovice.si', 'color': '#c60001'},
            {'css': 'sn', 'domain': 'poc-sn.stage.delo.si', 'color': '#c60001'},
            {'css': 'sn', 'domain': 'local-sn.delo.si', 'color': '#c60001'},
            {'css': 'ok', 'domain': 'odprtakuhinja.delo.si', 'color': '#0f9d58'},
            {'css': 'ok', 'domain': 'poc-ok.stage.delo.si', 'color': '#0f9d58'},
            {'css': 'ok', 'domain': 'local-ok.delo.si', 'color': '#0f9d58'},
        ];
        let found = false;
        for (let i = 0; i < portals.length; i++) {
            if (domain.includes(portals[i].domain)) {
                found = true
                setCss(portals[i].color + ';' + portals[i].css);
            }
        }
        if (!found) {
            setCss(css);
        }
    }

    const getIp = async () => {
        let response = await axios.get(ipUrl);
        setIp(response.data.ip);
    };

    const fetchLocalStorage = (step) => {
        if (step) {
            setSection(step);
        }
        if (JSON.parse(localStorage.getItem("poll_app_" + app_id + section))) {
            let b = JSON.parse(localStorage.getItem("poll_app_" + app_id + section));
            setVoter(b);
            setResult(true);
        }
    }

    const initComponent = () => {
        classByDomain();
        getIp().catch(console.error);
        fetchQuestions().catch(console.error);
        fetchLocalStorage();
    }

    useEffect(() => {
        initComponent();
    }, []);

    useEffect(() => {
        fetchLocalStorage();
    }, [section]);


    const clickAnswer = async (id) => {
        setCurrent(current + 1);
        setAnswer(id);
        let obj = {poll: app_id, section: section, answer: id, timestamp: new Date().getTime()}
        localStorage.setItem('poll_app_' + app_id + section, JSON.stringify(obj))
        let answers = 0;
        for (let i = 0; i < questions[section].answers.length; i++) {
            if (questions[section].answers[i].answer !== null && questions[section].answers[i].answer !== undefined) {
                answers += 1;
            }
        }
        const headers = {'x-requested-with': 'INCREDIBLYCOMPLICATEDTOKEN'};
        await axios.post(pollUrl + data.id + '/' + section + '/', {
            section: section,
            vote: id,
            ip: ip,
            id: data.id,
            title: data.title,
            answers: answers,
            sections: questions.length,
            answer: questions[section].answers[answer].answer,
            question: questions[section].question,
            created: new Date()
                .toISOString()
                .slice(0, 19)
                .replace(/-/g, "/")
                .replace("T", " "),
        }, {headers});
        setResult(true);
    }

    const clickNext = (step) => {
        setSection(step);
        if (section + 1 === questions.length) {
            return setResult(false);
        }
        if (voter) {
            fetchLocalStorage(step);
            if (voter.section === step) {
                return setResult(true);
            }
        } else if (step < current) {
            setSection(current)
        }

        setResult(false);
    }

    const clickResults = (step) => {
        setResult(true);
        setSection(step);
    }

    const isImage = (url) => {
        return /\.(jpg|jpeg|png|webp|avif|gif|svg|JPG|JPEG|PNG|WEBP|AVIF|GIF|SVG)$/.test(url);
    };

    let display = <Question data={data} questions={questions} section={section} onClick={clickAnswer} css={css}/>
    if (result) {
        display = <Result
            app_id={app_id}
            section={section}
            questions={questions}
            css={css}
            cat_id={catId}
        />
    } else if (section === questions.length) {
        display = <UserData
            title={data.title}
            app_id={app_id}
            form={form}
            css={css}
            ip={ip}
            section={section}
            total={questions.length}
            endText={endText}
            terms={terms}
        />
    }

    let buttonResults = '';
    if (section > 0) {
        buttonResults = <div>
            <button onClick={() => clickResults(section - 1)}
                    className={`waves-effect waves-light btn quiz-${css.split(';')[1]}`}><i
                className="material-icons">fast_rewind</i> <span>REZULTATI</span></button>
        </div>;
    }
    let buttonNext = '';
    if ((result && section + 1 < questions.length) || (form && result)) {
        buttonNext = <div className="result-next">
            <button onClick={() => clickNext(section + 1)}
                    className={`waves-effect waves-light btn quiz-${css.split(';')[1]}`}>NAPREJ
            </button>
        </div>;
    }

    let header = '';
    if(isImage(data.image)) {
        header = <img src={data.image + `?v=${Date.now()}`} alt={data.title}  className="quiz-title-image"/>
    }

    return (
        <div className={`materializecss quiz-app category-${catId}`}>
            <div className="row">
                <div className="row">
                    <div className="s12 quiz-header">
                        <div className="quiz-header">
                            {header}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className={`s12 quiz-content poll-${catId}`}>
                        {display}
                    </div>
                </div>
                <div className="row">
                    <div className="col s4"></div>
                    <div className="col s1">
                        {buttonResults}
                    </div>
                    <div className="col s1"></div>
                    <div className="col s6">{buttonNext}</div>
                </div>
            </div>
        </div>
    )
}

export default App;
