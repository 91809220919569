import React, { useState, useEffect } from 'react'

function Answer({ obj, aid, onClick, section, css }) {
  const [previous, setPrevious] = useState('')

  const initComponent = () => {
      if(section !== previous){
          var ele = document.getElementsByName("response");
          for(var i=0;i<ele.length;i++){
              ele[i].checked = 0;
          }
      }
  }

  const handleChange = (event) => {
    setPrevious(section)
    onClick(aid);
  };

  useEffect(() => {
        initComponent();
    }, []);

  const htmlDecode = (input) => {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
  };

  return (
    <div className="quiz-radio">
        <label>
            <input
                className={`${css}-radio`}
                type="radio"
                name="response"
                value={aid}
                onChange={(event) => handleChange(event)} />
            <span dangerouslySetInnerHTML={{__html: htmlDecode(obj.answer)}}></span>
        </label>
    </div>
  )
}

export default Answer;
