import React, {useState, useEffect} from 'react'
import Question from "./components/question";
import Header from "./components/header";
import Paginator from "./components/paginator";
import axios from "axios";

function App({type_id, app_type}) {
    const [id, setId] = useState(type_id);
    const [questions, setQuestions] = useState({});
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [endText, setEndText] = useState("")
    const [terms, setTerms] = useState("")
    const [header, setHeader] = useState("");
    const [nr, setNr] = useState(0);
    const [result, setResult] = useState([]);
    const [form, setForm] = useState([]);
    const [catId, setCatId] = useState(1)
    const [css, setCss] = useState("delo");
    const [answer, setAnswer] = useState([]);
    const [ip, setIp] = useState("");
    const [score, setScore] = useState(0);
    const [color, setColor] = useState('#FFFFFF');
    const dataUrl = window.location.origin + "/services/quiz/get/";
    const ipUrl = window.location.hostname !== 'local-sn.delo.si' ? "https://uporabnik.delo.si/services/promo/collect/" :
      "http://0.0.0.0:8000/services/promo/collect/";

    const fetchData = new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve({
                data:
                    {
                        "id": 3,
                        "title": "Re&scaron;ite zabavni praznični kviz",
                        "live": true,
                        "first_published_at": null,
                        "description": "&lt;p&gt;Praznična anketa na Slovenskih novicah. Bogate nagrade.&lt;/p&gt;\r\n",
                        "end_text": "",
                        "terms_of_use_url": "",
                        "questions": [
                            {
                                "id": 0,
                                "question": "Kje zivi Bozicek?",
                                "image": "/media/original_images/se/kviz/2/klobuk.JPG",
                                "image_credits": "",
                                "state": 1,
                                "answers": [
                                    {
                                        "id": 0,
                                        "answer": "Na severnem polu",
                                        "points_up": 0,
                                        "points_down": 0,
                                        "correct": 1,
                                        "state": 1
                                    },
                                    {},
                                    {},
                                    {}
                                ]
                            },
                            {
                                "id": 0,
                                "question": "Kako je ime bozickovem jelencku?",
                                "image": "/media/original_images/se/kviz/2/adidas-solar-drive-19-w-zenski-1.png",
                                "image_credits": "",
                                "state": 1,
                                "answers": [
                                    {
                                        "id": 0,
                                        "answer": "Rudolf",
                                        "points_up": 0,
                                        "points_down": 0,
                                        "correct": 1,
                                        "state": 1
                                    },
                                    {
                                        "id": 0,
                                        "answer": "Dudolf",
                                        "points_up": 0,
                                        "points_down": 0,
                                        "correct": 0,
                                        "state": 1
                                    },
                                    {
                                        "id": 0,
                                        "answer": "Mudonja",
                                        "points_up": 0,
                                        "points_down": 0,
                                        "correct": 0,
                                        "state": 1
                                    },
                                    {
                                        "id": 0,
                                        "answer": null,
                                        "points_up": 0,
                                        "points_down": 0,
                                        "correct": 0,
                                        "state": 1
                                    }
                                ]
                            },
                            {
                                "id": 0,
                                "question": "Katero prevozno sredstvo uporablja Bozicek?",
                                "image": "/media/original_images/se/kviz/2/sn_logo-200x128.png",
                                "image_credits": "",
                                "state": 1,
                                "answers": [
                                    {
                                        "id": 0,
                                        "answer": "Brezogljicno vozilo",
                                        "points_up": 0,
                                        "points_down": 0,
                                        "correct": 0,
                                        "state": 1
                                    },
                                    {
                                        "id": 0,
                                        "answer": "Biorazgradljivo vozilo",
                                        "points_up": 0,
                                        "points_down": 0,
                                        "correct": 1,
                                        "state": 1
                                    },
                                    {
                                        "id": 0,
                                        "answer": "Elektricno vozilo",
                                        "points_up": 0,
                                        "points_down": 0,
                                        "correct": 0,
                                        "state": 1
                                    },
                                    {
                                        "id": 0,
                                        "answer": null,
                                        "points_up": 0,
                                        "points_down": 0,
                                        "correct": 0,
                                        "state": 1
                                    }
                                ]
                            }
                        ],
                        "result_msgs": "{'0': 'Zanič', '1_10': 'ena', '11_20': 'dva', '21_30': 'tri', '31_40': '&scaron;tiri', '41_50': 'pet', '51_60': '&scaron;est', '61_70': 'sedem', '71_80': 'osem', '81_90': 'devet', '91_99': 'deset', '100': 'Bravo'}",
                        "image": "/media/original_images/se/kviz/2/boz-klobuk.JPG",
                        "cat_id": 2,
                        "form": [
                            /*{
                                "label": "Ime",
                                "type": "text",
                                "required": 1,
                                "name": "first_name"
                            },
                            {
                                "label": "Priimek",
                                "type": "text",
                                "required": 1,
                                "name": "last_name"
                            },
                            {
                                "label": "E-po&scaron;ta",
                                "type": "email",
                                "required": 1,
                                "name": "email"
                            },
                            {
                                "label": "Soglasje",
                                "type": "checkbox",
                                "required": 1,
                                "name": "gdpr"
                            }*/
                        ]
                    }
            });
        }, 300);
    });

    const fetchQuestions = async () => {
        const response = await axios.get(dataUrl + id);
        // const response = await fetchData;

        setId(response.data.id);
        setTitle(response.data.title);
        setDescription(response.data.description);
        setHeader(response.data.image + `?v=${Date.now()}`);
        setQuestions(response.data.questions);
        setCatId(response.data.cat_id);
        setColor(response.data.name);
        setEndText(response.data.end_text);
        setTerms(response.data.terms_of_use_url);
        // Adding checked attribute to checkbox
        var f = response.data.form;
        for (var i = 0; i < f.length; i++) {
            if (f[i].type == "checkbox") {
                f[i].checked = ""; //f[i].required == 1 ? true : false;
            }
        }
        setForm(f);
    };

    const classByDomain = () => {
        let domain = window.location.origin;
        let portals = [
            {'css': 'delo', 'domain': 'www.delo.si'},
            {'css': 'delo', 'domain': 'poc-delo.stage.delo.si'},
            {'css': 'delo', 'domain': 'local-delo.delo.si'},
            {'css': 'sn', 'domain': 'slovenskenovice.si'},
            {'css': 'sn', 'domain': 'poc-sn.stage.delo.si'},
            {'css': 'sn', 'domain': 'local-sn.delo.si'},
            {'css': 'ok', 'domain': 'odprtakuhinja.delo.si'},
            {'css': 'ok', 'domain': 'poc-ok.stage.delo.si'},
            {'css': 'ok', 'domain': 'local-ok.delo.si'},
        ];
        let found = false;
        for (let i = 0; i < portals.length; i++) {
            if (domain.includes(portals[i].domain)) {
                found = true
                setCss(portals[i].css);
            }
        }
        if (!found) {
            setCss(css);
        }
    }

    const getIp = async () => {
      let response = await axios.get(ipUrl);
      setIp(response.data.ip);
    };

    const initComponent = () => {
        classByDomain();
        getIp();
        fetchQuestions();
    }

    useEffect(() => {
        initComponent();
    }, []);

    const clickAnswer = (id) => {
        let update = false;
        for (var i = 0; i < result.length; i++) {
            if (result[i][questions[nr].question]) {
                result[i][questions[nr].question] = questions[nr].answers[id].answer
                answer[nr] = id
                setAnswer(answer)
                update = true
            }
        }
        if (update === false) {
            setResult([
                ...result,
                {
                    [questions[nr].question]: questions[nr].answers[id].answer,
                },
            ]);
            setAnswer([...answer,
                answer[nr] = id
            ])
        }
        if (questions[nr].answers[id].correct){
            setScore(score + 1);
        }
        if (app_type !== 'quiz-moderate') {
            setNr(nr + 1);
        }
    };

    const resetQuiz = () => {
        setNr(0);
        setResult([]);
        setAnswer([]);
        setScore(0);
    };

    const clickPaginator = (nr) => {
        let pnr = nr > 0 ? nr - 1 : 0;
        for (var i = 0; i < result.length; i++) {
            if (result[i][questions[pnr].question] !== undefined) {
                setNr(nr);
                break;
            }
        }
    };

    let paginator = '';
    if(catId === 3){
        app_type = 'quiz-moderate';
    }
    if (app_type === 'quiz-moderate' && (nr < questions.length)) {
        paginator = <Paginator nr={nr} onClick={clickPaginator} css={css}/>
    }

    return (
        <div className={`materializecss quiz-app category-${catId}`} style={{backgroundColor: color}}>
            <div className="row">
                <div className="row">
                    <div className="s12 quiz-header">
                        <Header header={header} title={title}/>
                    </div>
                </div>
                <div className="row">
                    <div className="s12 quiz-content">
                        <Question
                            id={id}
                            description={description}
                            title={title}
                            result={result}
                            nr={nr}
                            questions={questions}
                            onClick={clickAnswer}
                            resetQuiz={() => resetQuiz}
                            form={form}
                            appType={app_type}
                            css={css}
                            answer={answer}
                            ip={ip}
                            url={ipUrl}
                            score={score}
                            endText={endText}
                            terms={terms}
                        />
                    </div>
                </div>

            </div>
            <div className="row">{paginator}</div>
        </div>
    );
}

export default App;

