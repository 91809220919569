import React, {useState} from 'react'
import { createRoot } from 'react-dom/client';
import axios from 'axios'
import ArticleTeaser from '../article/article_teaser'

function LoadMoreCategoryArchive() {
    const styleSpan = {
        color: 'rgba(255, 255, 255, 0.5)'
    }
    const [count, setCount] = useState(10)
    const [maxCount, setMaxCount] = useState(60)
    const [results, setResults] = useState([])

    const handleClick = async event => {
        const result = await axios(
            '/services/get_more_articles/' + count + '/6/' + page_path
        )
        setResults([...results, ...result.data.articles])
        setMaxCount(result.data.count)
        setCount((count+6))
    }

    const listItems = results.map((item, index) => {
        return (<ArticleTeaser key={index} article={item} hassubtitle={false}/>)
    })

    return (
        <>
            {results.length > 0 &&
            <div className="group_f">
                {listItems}
            </div>
            }
            {count + 6 <= maxCount &&
            <div className='materializecss'>
                <button className='btn waves-effect waves-light show_more' onClick={() => handleClick()}>
                    Naloži več &nbsp;
                    {count>10 &&
                    <span style={styleSpan}>{count}/{maxCount}</span>
                    }
                </button>
            </div>
            }
        </>
    );
}

window.addEventListener('load', function () {
    let element = document.getElementById("load_more_category_archive")
    element ? createRoot(element).render(<LoadMoreCategoryArchive/>) : ''
});