import React from 'react'

const ArticleIcons = ({article}) => {
    const AIcons = () => {
        if (article.promo) {
            return (
                <img src='/static/delo/images/article_icons/promo.png'
                     className="article_icon icon--promo"
                     alt="Promo"/>
            )
        }
        else if (article.video) {
            return (
                <img src='/static/delo/images/article_icons/video.png'
                     className="article_icon icon--video"
                     alt="Video"/>
            )
        }
        else if (article.videowall) {
            return (
                <img src='/static/delo/images/article_icons/PayPlay_B_icon.png'
                     className="article_icon icon--premium"
                     alt="VideoWall"/>
            )
        }
        else if (article.image_list && article.image_list.length >  6) {
            return (
                <img src='/static/delo/images/article_icons/camera.png'
                     className="article_icon icon--photo"
                     alt="Photo"/>
            )
        } else {
            return null
        }
    }
    return (
        <span className="article_icons">
            <AIcons/>
        </span>
    )
}
export default ArticleIcons