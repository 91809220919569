import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./app";

window.addEventListener('load', function () {
    // <div id="dds-poll" data-id="2"></div>
    let element = document.getElementById('dds-poll')
    if (element) {
        let id = element.dataset.id
        createRoot(element).render(<App app_id={id}/>, element)
    }
})