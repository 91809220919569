import React, { useState, useEffect } from 'react'
import axios from "axios";
import InputElement from "./input_element";

function UserData({ id, title, result, resetQuiz, form, appType, css, ip, url, score, total, endText, terms }) {
  const [formError, setFormError] = useState("");
  const [finish, setFinish] = useState(false);
  const [inputFields, setInputFields] = useState(form);

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const initComponent = () => {
    if(appType === 'quiz-simple' || form.length <= 0){
      pushData();
      setFinish(true);
    }
  }

  useEffect(() => {
      initComponent()
    }, []);

  const pushData = async () => {
    let data = [];
    if(appType !== 'quiz-simple') {
      for (var i = 0; i < inputFields.length; i++) {
        if (inputFields[i].type === "checkbox") {
          data[i] = {
            [htmlDecode(inputFields[i].label)]: inputFields[i].checked ? "1" : "0",
          };
        } else {
          data[i] = {
            [htmlDecode(inputFields[i].label)]: htmlDecode(inputFields[i][inputFields[i].name]),
          };
        }
      }
    }

    const headers = { 'x-requested-with': 'INCREDIBLYCOMPLICATEDTOKEN' };
    await axios.post(url, {
      data: data,
      ip: ip,
      result: result,
      id: id,
      title: title,
      created: new Date()
        .toISOString()
        .slice(0, 19)
        .replace(/-/g, "/")
        .replace("T", " "),
    }, {headers});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let post = true;
    for (var i = 0; i < inputFields.length; i++) {
      if (inputFields[i].name === "email") {
        if (!validateEmail(inputFields[i].email)) {
          post = false;
          setFormError("Prosimo vnesite pravilen e-poštni naslov.");
        }
      }
    }

    if (post) {
      pushData();
      setFinish(true);
    }
  };

  const htmlDecode = (input) => {
        let doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    }

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    if (event.target.type === "checkbox") {
      data[index]["checked"] = event.target.checked;
    } else {
      data[index][event.target.name] = event.target.value;
    }
    setInputFields(data);
  };

  if (finish) {
    return (<>
        <div className="row">
          <h2 className="result-title">Hvala za sodelovanje!</h2>
          <p className="result-title">PRAVILNI ODGOVORI: {score}/{total}</p>
          {Boolean(endText) && <p>{endText}</p>}
          <div className="quiz-right col s12">
            <button className={`waves-effect waves-light btn quiz-${css}`} onClick={resetQuiz()}>
              IGRAJ ŠE ENKRAT
            </button>
          </div>
        </div>
        {Boolean(terms) && <div className="row">
          <i className="tiny material-icons">info_outline</i>
          <a className="terms" href={terms} target="_blank">Pravila
            in pogoji uporabe</a>
        </div>}
    </>
    )
    // return (
    //     <div className="row">
    //       <h2 className="result-title">Sodelujte v nagradnem žrebu</h2>
    //       <p>Povečajte si možnost za nagrado in delite kviz s prijatelji.</p>
    //       <div className="quiz-right col s12">
    //         <button className={`waves-effect waves-light btn quiz-${css}`} onClick={resetQuiz()}>
    //           IGRAJ ŠE ENKRAT
    //         </button>
    //       </div>
    //   </div>
    // );
  } else {
    return (
      <div className="row">
        <h2 className="result-title">Hvala za sodelovanje</h2>
        <p className="result-title">PRAVILNI ODGOVORI: {score}/{total}</p>
        {Boolean(endText) && <p>{endText}</p>}
        <p className="form-error">{formError}</p>
        <form onSubmit={handleSubmit}>
          <InputElement inputFields={inputFields} handleFormChange={handleFormChange} />
          <div className="row">
            <div className="quiz-right col s12">
              <button className={`waves-effect waves-light btn quiz-${css}`}>POŠLJI</button>
            </div>
          </div>
          {Boolean(terms) && <div className="row">
            <i className="tiny material-icons">info_outline</i>
            <a className="terms" href={terms} target="_blank">Pravila in pogoji uporabe</a>
          </div>}
        </form>
      </div>
    );
  }
}

export default UserData;
