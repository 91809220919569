import React from 'react'

function Paginator ({nr, onClick, css}) {

    const handleClickBack = () => {
        if(nr > 0) {
            onClick(nr - 1);
        }
    };
    const handleClickForward = () => {
        onClick(nr+1);
    };
    let back = '';
    if(nr>0) {
        back = <button onClick={handleClickBack} className={`waves-effect waves-light btn quiz-${css}`}>NAZAJ</button>
    }

    return (
    <div className="row">
        <div className="col s4"></div>
        <div className="col s1">
            {back}
        </div>
        <div className="col s1"></div>
        <div className="col s6">
            <button onClick={handleClickForward} className={`waves-effect waves-light btn quiz-${css}`}>NAPREJ</button>
        </div>
    </div>
    )
}

export default Paginator;