import React from 'react'
import Answer from "./answer";

function AnswerList({ answers, onClick, appType, nr, answer, css }) {
  const handleSubmit = (event) => {
    event.preventDefault();
  }

  if (answers.length > 0) {
    const renderedAnswers = answers.map((obj) => {
      if (obj.answer !== null && obj.answer !== undefined) {
        return (
          <Answer
            aid={answers.indexOf(obj)}
            key={answers.indexOf(obj)}
            obj={obj}
            onClick={onClick}
            appType={appType}
            nr={nr}
            answer={answer}
            css={css}
          />
        );
      }
    });

    if(appType !== 'quiz-moderate') {
      return <div>{renderedAnswers}</div>;
    }else{
      return <div><form onSubmit={handleSubmit} id={nr}>{renderedAnswers}</form></div>;
    }
  }
}

export default AnswerList;
