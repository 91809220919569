import AnswerList from "./answer_list";
import UserData from "./user_data";
import React, { useEffect } from 'react'

function Question({
  id,
  resetQuiz,
  title,
  description,
  result,
  nr,
  questions,
  onClick,
  form,
  appType,
  css,
  answer,
  ip,
  url,
  score,
  endText,
  terms
}) {
  const htmlDecode = (input) => {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
  };

  const isImage = (url) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  };

  let question = questions[nr];

  if (questions.length > 0) {
    if (questions.length > nr) {
      let image = '';
      if (isImage(question.image)){
        image = <img src={question.image} alt={question.question} className="quiz-question-image"/>
      }
      return (
        <div>
          <h2 className="quiz-title" dangerouslySetInnerHTML={{ __html: htmlDecode(title) }}></h2>
          <div
            className="quiz-description"
            dangerouslySetInnerHTML={{ __html: htmlDecode(description) }}
          ></div>
          <div className="quiz-question-title" dangerouslySetInnerHTML={{ __html: htmlDecode(question.question) }}></div>
          {image}
          <div className="quiz-answer-box">
            <AnswerList answers={question.answers} onClick={onClick} appType={appType} nr={nr} answer={answer}  css={css}/>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <UserData
            result={result}
            resetQuiz={resetQuiz}
            title={title}
            id={id}
            form={form}
            appType={appType}
            css={css}
            ip={ip}
            url={url}
            score={score}
            total={questions.length}
            endText={endText}
            terms={terms}
          />
        </div>
      );
    }
  } else {
    return <div>Ni podatkov</div>;
  }
}

export default Question;