import React from 'react'
import Answer from "./answer";

function AnswerList({ answers, onClick, section, css }) {

  if (answers.length > 0) {
    const renderedAnswers = answers.map((obj) => {
      if (obj.answer !== null && obj.answer !== undefined) {
        return (
          <Answer
            aid={answers.indexOf(obj)}
            key={answers.indexOf(obj)}
            obj={obj}
            onClick={onClick}
            section={section}
            css={css}
          />
        );
      }
    });

   return <div>{renderedAnswers}</div>;
  }
}

export default AnswerList;
