import React, {useEffect} from 'react'
import AnswerList from "./answer_list";

function Question({data, questions, section, onClick, css}) {
    const htmlDecode = (input) => {
        var e = document.createElement("div");
        e.innerHTML = input;
        return e.childNodes[0].nodeValue;
    };

    const isImage = (url) => {
        return /\.(jpg|jpeg|png|webp|avif|gif|svg|JPG|JPEG|PNG|WEBP|AVIF|GIF|SVG)$/.test(url);
    };
    let question = questions[section];

  if (questions.length > 0) {
      if (questions.length > section) {
          let image = '';
          if (isImage(question.image)){
            image = <img src={question.image} alt={question.question} className="quiz-question-image"/>
          }
          return (
        <div>
          <div
              className="quiz-question-title"
              dangerouslySetInnerHTML={{ __html: htmlDecode(question.question) }}>
          </div>
          {image}
          <div className="quiz-answer-box">
            <AnswerList answers={question.answers} onClick={onClick} section={section} css={css}/>
          </div>
        </div>
      );
      }
  }else{
        return <div>Ni podatkov</div>;
  }
}

export default Question;