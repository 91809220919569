import React, { useState } from 'react'


function InputElement({inputFields, handleFormChange}) {

    const htmlDecode = (input) => {
        let doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    }

    const handleChange = (index, event) => {
        handleFormChange(index, event);
    };

    const renderedFields = inputFields.map((input, index) => {
        if (input.name !== '' && input.label !== '') {
            if (input.type === "text" || input.type === "email") {
                if (input.required) {
                    return (
                        <div className="row" key={index}>
                            <input
                                className=""
                                type={input.type}
                                name={input.name}
                                value={input.val}
                                onChange={(event) => handleChange(index, event)}
                                placeholder={htmlDecode(input.label) + " *"}
                                required="required"
                            />
                        </div>
                    );
                } else {
                    return (
                        <div className="row" key={index}>
                            <input
                                className=""
                                type={input.type}
                                name={input.name}
                                value={input.val}
                                onChange={(event) => handleChange(index, event)}
                                placeholder={htmlDecode(input.label)}
                            />
                        </div>
                    );
                }
            } else if (input.type === "checkbox") {
                if (input.required) {
                    return (
                        <div className="row" key={index}>
                            <label>
                                <input
                                    className=""
                                    type={input.type}
                                    name={input.name}
                                    value="" //{input.name}
                                    onChange={(event) => handleChange(index, event)}
                                    required="required"
                                    checked={input.checked}
                                />
                                <span className="form_cb_label">{htmlDecode(input.label)} *</span>
                            </label>
                        </div>
                    );
                } else {
                    return (
                        <div className="row" key={index}>
                            <label>
                                <input
                                    className=""
                                    type={input.type}
                                    name={input.name}
                                    value="" //{input.name}
                                    onChange={(event) => handleChange(index, event)}
                                    checked={input.checked}
                                />
                                <span className="form_cb_label">{htmlDecode(input.label)}</span>
                            </label>
                        </div>
                    );
                }
            }
        }
    })

    return <div>{renderedFields}</div>
}

export default InputElement;