import React, { useState, useEffect } from 'react'

function Answer({ obj, aid, onClick, appType, nr, answer, css }) {
  const [previous, setPrevious] = useState('')

  const initComponent = () => {
      if(nr !== previous){
          var ele = document.getElementsByName("response");
          for(var i=0;i<ele.length;i++){
              ele[i].checked = (nr in answer) && answer[nr] === i;
          }
      }
  }

  const handleChange = (event) => {
    setPrevious(nr)
    onClick(aid);
  };

  const handleClick = (event) => {
    setPrevious(nr)
    onClick(aid);
  };

  useEffect(() => {
        initComponent();
    }, [nr]);

  const htmlDecode = (input) => {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
  };

  if(appType !== 'quiz-moderate') {
      return (
          <div className="butt_answer" onClick={handleClick}>
      <span className="answer-mark">
        {String.fromCharCode(97 + aid).toUpperCase()}
      </span>{" "}
              <span dangerouslySetInnerHTML={{__html: htmlDecode(obj.answer)}}></span>
          </div>
      );
  }else{
    return (
    <div className="quiz-radio">
        <label>
            <input
                className={`${css}-radio red`}
                type="radio"
                name="response"
                value={aid}
                onChange={(event) => handleChange(event)} />
            <span dangerouslySetInnerHTML={{__html: htmlDecode(obj.answer)}}></span>
        </label>
    </div>
    )
  }
}

export default Answer;
