import React from 'react'
import { createRoot } from 'react-dom/client';
import App from './app'
window.addEventListener('load', function () {
    // <div id="dds-quiz" data-id="2"></div>
    let element = document.getElementById('dds-quiz')
    if (element) {
        let id = element.dataset.id
        let type = element.dataset.type ? element.dataset.type : 'quiz'
        createRoot(element).render(<App type_id={id} app_type={type}/>, element)
    }
})
