import React from 'react'

function Header({ header, title }) {
  return (
    <div className="quiz-header">
      <img src={header} alt={title} className="quiz-title-image" />
    </div>
  );
}

export default Header;
