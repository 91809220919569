import React from 'react'
const Renditions = ({teaser_renditions, article, rand_id}) => {
    let renditions = {
        'xs': {'min': 0, 'max': 480, 'rendition': 'fill-512x288', 'height': '56.25%'},
        'sm': {'min': 480, 'max': 576, 'rendition': 'fill-512x288', 'height': '56.25%'},
        'smm': {'min': 576, 'max': 768, 'rendition': 'fill-512x288', 'height': '56.25%'},
        'md': {'min': 768, 'max': 800, 'rendition': 'fill-512x288', 'height': '56.25%'},
        'mdd': {'min': 800, 'max': 992, 'rendition': 'fill-512x288', 'height': '56.25%'},
        'lg': {'min': 992, 'max': 1280, 'rendition': 'fill-800x450', 'height': '56.25%'},
        'xl': {'min': 1280, 'max': 2000, 'rendition': 'fill-800x450', 'height': '56.25%'},
        'xxl': {'min': 2000, 'max': 10000, 'rendition': 'fill-800x450', 'height': '56.25%'}
    }
    if(teaser_renditions == 'renditions2'){
        renditions = {
            'xs': {'min': 0, 'max': 480, 'rendition': 'fill-170x222', 'height': '120%'},
            'sm': {'min': 480, 'max': 576, 'rendition': 'fill-215x190', 'height': '80%'},
            'smm': {'min': 576, 'max': 768, 'rendition': 'fill-113x232', 'height': '190%'},
            'md': {'min': 768, 'max': 800, 'rendition': 'fill-145x220', 'height': '140%'},
            'mdd': {'min': 800, 'max': 992, 'rendition': 'fill-170x222', 'height': '120%'},
            'lg': {'min': 992, 'max': 1280, 'rendition': 'fill-145x220', 'height': '140%'},
            'xl': {'min': 1280, 'max': 2000, 'rendition': 'fill-145x220', 'height': '110%'},
            'xxl': {'min': 2000, 'max': 10000, 'rendition': 'fill-145x220', 'height': '110%'}
        }
    }
    if(!article) {
        return null
    } else if(article && article.image){
        return (
            <>
                <style>
                    {Object.entries(renditions).map(([key, value]) => {
                        let image_path = ''
                        try {
                            image_path = article.image.filter_spec[value.rendition].path
                        }
                        catch(err) {
                            image_path = ''
                        }
                        return (
                            `@media only screen and (min-width: ${value.min}px) and (max-width: ${value.max }px) {
                            .article_teaser__article-image--${rand_id} {
                                    background-image: url(${image_path});
                                    padding-top: ${value.height };
                                }
                            }`)
                    })}
                </style>
            </>
        );
    } else {
        return null
    }

}

export default Renditions;