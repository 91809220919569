import React, {useState} from 'react'
import Renditions from "./renditions";
import ArticleIcons from "./article_icons";

const ArticleTeaser = props => {
    const rand_id = getRandomInt(999999)
    const renditions = Renditions('renditions1')
    function getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max))
    }

    var article_url = props.article.cross_link
    var target = '_blank'

    if (props.article.url != ''){
        article_url = props.article.url
        target = '_self'
    }
    return (
        <div>
            <Renditions teaser_renditions='renditions2' article={props.article} rand_id={rand_id} />
            <div className="article_teaser article_teaser__horizontal box_shadow">
                <div className="article_teaser___vertical_box1">
                    <a className={`article_teaser__article article_teaser__article--${rand_id}   article_icons_holder bottom light_icons`}
                       href="/partnerstvo/druzba/evgenija-sumi-izjemna-slovenka-ki-je-vodila-tovarno-sladkarij-in-pomagala-revnim/" >
                        <div className={`article_teaser__article-image article_teaser__article-imageheight--${rand_id} article_teaser__article-image--${rand_id}`}
                             data-lazy={`article_teaser__article-image--${rand_id}`}>
                            &nbsp;
                        </div>
                        <ArticleIcons article={props.article} />
                    </a>
                </div>
                <div className="article_teaser___vertical_box2">
                    <a className={`article_teaser__title_link article_teaser__title_link_${rand_id}`}
                       href={article_url}
                       target={target}>
                        <h3 id={`article_teaser_title_${rand_id}`} className="article_teaser__title">
                            <span className="article_teaser__title_text" dangerouslySetInnerHTML={{ __html: `${props.article.title}&nbsp;` }}></span>
                            <span className="article_teaser__article_supertitle">
                                <span className="article_supertitle" dangerouslySetInnerHTML={{ __html: `<br>${props.article.supertitle}&nbsp;` }}></span>
                            </span>
                        </h3>
                    </a>
                    <div className="clear"></div>
                </div>
            </div>
        </div>
    );
}

export default ArticleTeaser;