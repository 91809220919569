import React, { useState, useEffect, createRef } from 'react'
import { createRoot } from 'react-dom/client';

import { audioPlayPause, audioRw, audioFw } from '../../../article/audio'

var getWindowOptionsMobile = function () {
    var width = 500;
    var height = 350;
    var left = (window.innerWidth / 2) - (width / 2);
    var top = (window.innerHeight / 2) - (height / 2);

    return [
        'resizable,scrollbars,status',
        'height=' + height,
        'width=' + width,
        'left=' + left,
        'top=' + top,
    ].join();
};
var url = '{{ request.build_absolute_uri }}'

var fbShareMobile = function () {
    FB.ui({
        method: 'share',
        href: url
    }, function (response) {
    });
};

var fbMessengerMobile = function () {
    FB.ui({
        method: 'send',
        link: url,
    }, function (response) {
    });
};

var twitterMobile = function (e) {
    var win = window.open('https://twitter.com/intent/tweet?url=' + url,
        '',
        getWindowOptionsMobile());
    win.opener = null;
};

function MobileFooter() {
    const modal_social = createRef()
    const modal_audio = createRef()
    const social_css = {
        textAlign: 'center',
        backgroundColor: "none",
        boxShaddow: 'nonde',
        padding: '20% 0',
    }
    const audio_css = {
        color: 'white',
        textAlign: 'center',
        backgroundColor: 'black',
        borderRadious: '50%',
        padding: '0.1em'
    }

    const goBack = e => {
        e.preventDefault()
        window.history.back()
    }
    const goTop = e => {
        e.preventDefault()
        window.scrollTo(0, 0)
    }

    const mobileShare = async e => {
        e.preventDefault()
        if(!navigator.share) {
            console.warn('Native Web Sharing not supported');
            var instance = M.Modal.init(modal_social.current, {})
            instance.open()
            // console.log(modal_social, instances)
            return
        }
        try {
            const shareData = {
                title: document.title,
                text: document.title,
                 url: window.location,
            }
            await navigator.share(shareData);
            console.log('Share successfull');
        } catch(err) {
            console.log('Error: ', err);
        }
    }

    useEffect(() => {
        const social_snippet = document.querySelector('.article__metadata--share .social')
        const audio_snippet = document.querySelector('.article__metadata--listen .article__audio_controls')
        if (!social_snippet || !audio_snippet) return
        const aCLone = audio_snippet.cloneNode(true)
        const sClone = social_snippet.cloneNode(true)

        modal_social.current.append(sClone)
        modal_audio.current.append(aCLone)
        document.querySelectorAll('.modal').forEach(val => M.Modal.init(val))

        document.querySelectorAll('.delo-pl').forEach(audioPlayPause)
        document.querySelectorAll('.delo-rw').forEach(audioRw)
        document.querySelectorAll('.delo-fw').forEach(audioFw)
    }, [])

    return (
        <div id="social_icons_mobile_footer" className="social_icons_mobile_footer">
            {/* START OF NON SOCIAL ICONS BY ROBERT SCHMITZER 23.3.22 */}
            <div className="other_icons_mobile">
                <div className="other__icon_container">
                    <div className="other__icon">
                        <a className="far fa-arrow-alt-circle-left" onClick={goBack}></a>
                    </div>
                </div>
                <div className="other__icon_container">
                    <div className="other__icon">
                        <a className="far fa-arrow-alt-circle-up" onClick={goTop}></a>
                    </div>
                </div>
            </div>
            {/* END OF NON SOCIAL ICONS BY ROBERT SCHMITZER 23.3.22 */}
            <span className="materializecss" style={{display:"block", float:"right", width: "auto"}}>
                {/*<a className="btn-floating btn-medium waves-effect waves-light modal-trigger white delo-mobile-float__button delo-mobile-float__button--share"*/}
                {/*   href="#modal_social">*/}
                {/*    <i className="fas fa-share-alt" style={{color:"black", transform: "scale(0.8)", paddingLeft: "0.1em"}}></i>*/}
                {/*</a>*/}
                <a className="btn-floating btn-medium waves-effect waves-light white delo-mobile-float__button delo-mobile-float__button--share"
                   href="#" onClick={mobileShare}>
                    <i className="fas fa-share-alt" style={{color:"black", transform: "scale(0.8)", paddingLeft: "0.1em"}}></i>
                </a>
                <a className="btn-floating btn-medium waves-effect waves-light modal-trigger white delo-mobile-float__button delo-mobile-float__button--play"
                   href="#modal_audio">
                    <i className="fas fa-play" style={{color:"black", transform: "scale(0.8)", paddingLeft: "0.1em"}}></i>
                </a>
            </span>
            <div className="clear"></div>
            <div className="materializecss">
                <div ref={modal_social} id="modal_social" className={'modal'} style={{...social_css}}></div>
                <div ref={modal_audio} id="modal_audio" className={'modal'} style={{...audio_css}}></div>
            </div>
        </div>
    )
}



window.addEventListener('load', function () {
    let element = document.getElementById("social_icons_mobile_footer")
    element ? createRoot(element).render(<MobileFooter/>) : null
});