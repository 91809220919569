import $ from 'jquery'
import settings from '../../../src/es6/settings'


// window.addEventListener('load', function() {
//     const audio = document.getElementById('article__audio')
//     if (!audio) return
//     generate_audio_actions(audio)
// })
//
//
// function generate_audio_actions(audio) {
//     const pl = 'fa-play-circle'
//     const pp = 'fa-pause-circle'
//
//     document.querySelectorAll('.delo-rw').forEach(el => {
//         $(el).click(function(e) {
//             e.preventDefault()
//             // $(audio).bind('canplay', function() {
//             //     this.currentTime -= 10;
//             // });
//             audio.currentTime -= 10;
//             ga('send', {
//                 hitType: 'event',
//                 eventCategory: 'Audio',
//                 eventAction: 'rw',
//                 eventLabel: window.location.href
//             });
//         })
//     })
//     document.querySelectorAll('.delo-fw').forEach(el => {
//         $(el).click(function(e) {
//             e.preventDefault()
//             // $(audio).bind('canplay', function() {
//             //     this.currentTime += 10;
//             // });
//             audio.currentTime += 10;
//             ga('send', {
//                 hitType: 'event',
//                 eventCategory: 'Audio',
//                 eventAction: 'fw',
//                 eventLabel: window.location.href
//             });
//         })
//     })
//     document.querySelectorAll('.delo-pl').forEach(el => {
//         $(el).click(function(e) {
//             e.preventDefault()
//             console.log('CLICKED')
//             if($(this).hasClass(pl)) {
//                 audio.play()
//                 ga('send', {
//                     hitType: 'event',
//                     eventCategory: 'Audio',
//                     eventAction: 'play',
//                     eventLabel: window.location.href
//                 });
//             }
//             if($(this).hasClass(pp)) {
//                 audio.pause()
//                 ga('send', {
//                     hitType: 'event',
//                     eventCategory: 'Audio',
//                     eventAction: 'pause',
//                     eventLabel: window.location.href
//                 });
//             }
//             $(this).toggleClass(pl).toggleClass(pp)
//         })
//     })
//
//     /**
//      * Get lost if our screen is too large
//      */
//     if (!['xs', 'sm', 'md'].includes(settings.viewSize)) return
//
//     const buttons = {
//         fork: document.querySelector('.delo-mobile .delo-mobile__button--fork'),
//         audio: document.querySelector('.delo-mobile .delo-mobile__button--audio')
//     }
//     Object.values(buttons).forEach(el => {
//         // trange strange, but we have to ask aourselves this
//         if(el) document.body.append(el)
//     })
// }

function audioPlayPause(el) {
    const audio = document.getElementById('article__audio')
    if (!audio) return
    const pl = 'fa-play-circle'
    const pp = 'fa-pause-circle'
    el.addEventListener('click', e => {
        e.preventDefault()
        if (el.classList.contains(pl)) {
            audio.play()
            document.querySelectorAll('.delo-pl').forEach(el => {
                el.classList.add(pp)
                el.classList.remove(pl)
            })
            if (window.ga) ga('send', {
                hitType: 'event',
                eventCategory: 'Audio',
                eventAction: 'play',
                eventLabel: window.location.href
            });
        } else {
            audio.pause()
            document.querySelectorAll('.delo-pl').forEach(el => {
                el.classList.add(pl)
                el.classList.remove(pp)
            })
            if (window.ga) ga('send', {
                hitType: 'event',
                eventCategory: 'Audio',
                eventAction: 'pause',
                eventLabel: window.location.href
            });
        }
    })
}
function audioFw(el) {
    const audio = document.getElementById('article__audio')
    if (!audio) return
    el.addEventListener('click', e => {
        e.preventDefault()
        audio.currentTime += 10;
        if (window.ga) ga('send', {
            hitType: 'event',
            eventCategory: 'Audio',
            eventAction: 'fw',
            eventLabel: window.location.href
        });
    })
}
function audioRw(el) {
    const audio = document.getElementById('article__audio')
    if (!audio) return
    el.addEventListener('click', e => {
        e.preventDefault()
        audio.currentTime -= 10;
        if (window.ga) ga('send', {
            hitType: 'event',
            eventCategory: 'Audio',
            eventAction: 'rw',
            eventLabel: window.location.href
        });
    })
}

/**
 * THIS IS DONE IN mobile_footer.js
 * if it was done here, events get fired multiple times
 */
// document.querySelectorAll('.delo-pl').forEach(audioPlayPause)
// document.querySelectorAll('.delo-rw').forEach(audioRw)
// document.querySelectorAll('.delo-fw').forEach(audioFw)

export {
    audioPlayPause, audioRw, audioFw
}
