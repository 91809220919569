import React, {useState, useEffect} from 'react'
import axios from "axios";
import Poll from "./poll";

function Result({ app_id, section, questions, css, cat_id}) {
    const [results, setResults] = useState([]);

    const pollUrl = window.location.hostname !== 'local-sn.delo.si' ? "https://uporabnik.delo.si/services/promo/poll/" :
        "https://local-dur.delo.si/services/promo/poll/";

    const fetchResult = async () => {
        const headers = {'x-requested-with': 'INCREDIBLYCOMPLICATEDTOKEN'};
        const response = await axios.get(pollUrl + app_id + '/' + section + '/', {headers});
        /*let arr = []
        for (let i = 0; i < response.data.results.length; i++) {
            arr[i] = response.data.results[i]
        }*/
        setResults(response.data.results);
    }

    const initComponent = () => {
        fetchResult().catch(console.error);
    }

    const htmlDecode = (input) => {
        var e = document.createElement("div");
        e.innerHTML = input;
        return e.childNodes[0].nodeValue;
    };

    useEffect(() => {
        initComponent();
    }, [section]);

    if (questions.length > 0 && results) {
        const renderedResults = results.map((obj, index) => {
            return (
                <Poll
                    name={questions[section].answers[index].answer}
                    obj={obj}
                    css={css}
                    key={index}
                    section={section}
                    order={index}
                    app_id={app_id}
                    cat_id={cat_id}
                />
            );
        });

        return (
            <div>
                <div className="result-question"
                     dangerouslySetInnerHTML={{ __html: htmlDecode(questions[section].question) }}>
                </div>
                <div className="result-title"></div>
                {renderedResults}
            </div>
        )
    }else{
        return <div>Ni podatkov</div>;
    }

}

export default Result;